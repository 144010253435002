.rankingList2 {
  font-family: PingFangSC-Regular;
}
.rankingList2 .rankingList2Box .userName {
  font-size: 0.29rem;
  font-weight: 500;
  color: #000000;
  padding-bottom: 0.1rem;
  position: relative;
  height: 0.52rem;
}
.rankingList2 .rankingList2Box .userName::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
}
.rankingList2 .rankingList2Box .rankItem {
  margin-top: 0.1rem;
  padding: 0 0.05rem;
  font-size: 0.25rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  overflow: hidden;
}
.rankingList2 .rankingList2Box .rankItem span {
  float: right;
}
.rankingList2 .rankingList2Box .order img {
  height: 0.37rem;
  margin-right: 0.1rem;
  vertical-align: -0.1rem;
}
.rankingList2 .rankingList2Box .amount span {
  font-size: 0.27rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #F13333;
}
.rankTop {
  padding-top: 2.3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0.3rem 0.3rem;
}
.rankTop .item {
  color: #FFFFFF;
  font-size: 0.28rem;
  text-align: center;
}
.rankTop .item .icon {
  position: relative;
  width: 1.5rem;
}
.rankTop .item .icon img {
  display: block;
}
.rankTop .item .icon .mask {
  position: relative;
  z-index: 2;
  width: 1.5rem;
}
.rankTop .item .icon .avatar {
  z-index: 1;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  position: absolute;
  left: 0.14rem;
  top: 0.4rem;
}
.rankTop .item.first .icon {
  width: 2rem;
}
.rankTop .item.first .mask {
  width: 2rem;
}
.rankTop .item.first .avatar {
  width: 1.4rem;
  height: 1.4rem;
  left: 0.35rem;
  top: 0.48rem;
}
.layoutBox {
  margin: 0 0.2rem;
  margin-top: 0.2rem;
  border-radius: 0.48rem 0.48rem 0 0;
  padding: 0.4rem 0;
  position: relative;
  z-index: 10;
}
.layoutBox .title {
  font-family: PingFangSC-Medium;
  padding-bottom: 0.4rem;
  color: #333333;
  font-size: 0.32rem;
  text-align: center;
  font-weight: bold;
}
.rankInfo {
  position: relative;
  padding-top: 0.88rem;
  padding-top: calc(env(safe-area-inset-top) + 0.88rem);
  left: 0;
  width: 100%;
}
.rankInfo::before {
  content: "";
  height: 4.42rem;
  height: calc(env(safe-area-inset-top) + 4.42rem);
  width: 100%;
  background: url(../../assets/imgs/new_color/rang_bg.png) no-repeat center top;
  background-size: 100% 100%;
  display: inline-block;
  position: absolute;
  top: 0;
}
.rankInfo .cont {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  margin: 0 0.2rem;
  color: #333333;
  font-size: 0.32rem;
  padding: 0.22rem 0;
  background: #FFFFFF;
  box-shadow: 0px 0.02rem 0.22rem 0px rgba(104, 104, 104, 0.15);
  border-radius: 0.32rem;
  font-weight: bold;
  margin-top: 3rem;
}
.rankInfo .cont .item {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PingFangSC-Medium;
}
.rankInfo .cont .item .mainFontLightColor {
  color: #6F7A8B !important;
  font-size: 0.24rem;
  margin-top: 0.08rem;
  font-family: PingFangSC-Regular;
}
.rankInfo .cont .item:last-child {
  border-left: 1px solid #D3D9DE;
}
.rankingList {
  display: flex;
  align-items: center;
  margin: 0 0.2rem;
  margin-top: 0.16rem;
}
.rankingList:first-child {
  margin-top: 0;
}
.rankingList .num {
  margin-right: 0.17rem;
}
.rankingList .num .txt {
  font-family: PingFangSC-Medium;
  margin: 0 auto;
  width: 0.43rem;
  height: 0.64rem;
  line-height: 0.64rem;
  color: #333333;
  font-size: 0.32rem;
  text-align: center;
  font-weight: bold;
}
.rankingList .num .img img {
  width: 0.38rem;
  height: 0.43rem;
}
.rankingList .avatar {
  margin-right: 0.22rem;
}
.rankingList .avatar img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  display: block;
}
.rankingList .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 0.05rem;
  padding-bottom: 0.15rem;
}
.rankingList .info .username {
  color: #333333;
  font-size: 0.28rem;
}
.rankingList .info .reward {
  color: #6F7A8B;
  font-size: 0.24rem;
  line-height: 0.35rem;
  padding-top: 0.08rem;
}
.rankingList .info .reward span:last-child {
  margin-left: 0.17rem;
}
.rankingList .info .reward i {
  width: 0.02rem;
  height: 0.2rem;
  background: #F5F5F5;
  display: inline-block;
  margin: 0 0.1rem;
}
.rankingList .info .download {
  float: right;
}
.rankingList .info .download img {
  width: 0.32rem;
  height: 0.32rem;
  display: block;
}
.rankingList:last-child .info {
  border-bottom: 0;
}
