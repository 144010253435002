



































































































































































    .rankingList2 {
		font-family: PingFangSC-Regular;
		// background: url(../../assets/imgs/rank/rankinglist_bg.png) no-repeat center top;
		// background-size: 100% auto;
        .rankingList2Box {
            // padding: 0.3rem;
			// padding-top: 2.3rem;
            // border-bottom: 1px solid #f1f1f1;

            .userName {
                font-size: 0.29rem;
                // font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
                padding-bottom: 0.1rem;
				position: relative;
				height: .52rem;
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
				}
            }

            .rankItem {
                margin-top: 0.1rem;
                padding: 0 0.05rem;
                font-size: 0.25rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                overflow: hidden;

                span {
                    float: right;
                }

            }

            .order {
                img {
                    height: 0.37rem;
                    margin-right: 0.1rem;
                    vertical-align: -0.1rem;
                }
            }

            .amount {
                span {
                    font-size: 0.27rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #F13333;
                }
            }
        }
    }

// ------20200410[start]------
.rankTop {
	padding-top: 2.3rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 0 .3rem .3rem;
	.item {
		color: #FFFFFF;
		font-size: .28rem;
		text-align: center;
		.icon {
			position: relative;
			width: 1.5rem;
			img {
				display: block;
			}
			.mask {
				position: relative;
				z-index: 2;
				width: 1.5rem;
			}
			.avatar {
				z-index: 1;
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 50%;
				position: absolute;
				left: .14rem;
				top: .4rem;
			}
		}
		&.first {
			.icon {
				width: 2rem;
			}
			.mask {
				width: 2rem;
			}
			.avatar {
				width: 1.4rem;
				height: 1.4rem;
				left: .35rem;
				top: .48rem;
			}
		}
	}
}

.layoutBox {
	// padding: .25rem .3rem;
	margin: 0 0.2rem;
	margin-top: 0.2rem;
	border-radius: .48rem .48rem 0 0;
	padding: 0.4rem 0;
	position: relative;
	z-index: 10;
	.title {
		font-family: PingFangSC-Medium;
		padding-bottom: 0.4rem;
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		font-weight: bold;
	}
}

.rankInfo {
	position: relative;
	// top: 0.88rem;
	padding-top: 0.88rem;
	padding-top: calc(env(safe-area-inset-top) + 0.88rem);
	left: 0;
	width: 100%;
	&::before{
		content: "";
		height: 4.42rem;
		height: calc(env(safe-area-inset-top) + 4.42rem);
		width: 100%;
		background: url(../../assets/imgs/new_color/rang_bg.png) no-repeat center top;
		background-size: 100% 100%;
		display: inline-block;
		position: absolute;
		top: 0;
	}
	.cont {
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 10;
		margin: 0 0.2rem;
		color: #333333;
		font-size: .32rem;
		// line-height: .4rem;
		padding: 0.22rem 0;
		background: #FFFFFF;
		box-shadow: 0px 0.02rem 0.22rem 0px rgba(104, 104, 104, 0.15);
		border-radius: 0.32rem;
		font-weight: bold;
		margin-top: 3rem;
		.item {
			width: 50%;
			// min-width: 2rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			font-family: PingFangSC-Medium;
			.mainFontLightColor {
				color: #6F7A8B !important;
				font-size: 0.24rem;
				margin-top: 0.08rem;
				font-family: PingFangSC-Regular;
			}
			&:last-child {
				border-left: 1px solid #D3D9DE;
			}
		}
	}
}

.rankingList {
	display: flex;
	align-items: center;
	margin: 0 0.2rem;
	margin-top: 0.16rem;
	&:first-child {
		margin-top: 0;
	}
	.num {
		// font-family: Bahnschrift;
		margin-right: 0.17rem;
		.txt {
			font-family: PingFangSC-Medium;
			margin: 0 auto;
			width: 0.43rem;
			height: 0.64rem;
			line-height: 0.64rem;
			color: #333333;
			font-size: .32rem;
			text-align: center;
			font-weight: bold;
			// font-style: italic;
		}
		.img {
			img {
				width: 0.38rem;
				height: 0.43rem;
			}
		}
	}
	.avatar {
		margin-right: 0.22rem;
		img {
			width: .8rem;
			height: .8rem;
			border-radius: 50%;
			display: block;
		}
	}
	.info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;
		// border-bottom: 1px solid #EDEDED;
		padding-top: .05rem;
		padding-bottom: .15rem;
		.username {
			color: #333333;
			font-size: .28rem;
			// line-height: .4rem;
		}
		.reward {
			color: #6F7A8B;
			font-size: .24rem;
			line-height: .35rem;
			padding-top: 0.08rem;
			span:last-child {
				margin-left: 0.17rem;
			}
			i {
				width: 0.02rem;
				height: .2rem;
				background: #F5F5F5;
				display: inline-block;
				margin: 0 .1rem;
			}
		}
		.download {
			float: right;
			// padding-top: .3rem;
			img {
				width: 0.32rem;
				height: 0.32rem;
				display: block;
			}
		}
	}
	
	
	&:last-child {
		.info {
			border-bottom: 0;
		}
	}
}

// ------20200410[start]------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;